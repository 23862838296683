import React from 'react';
import Gematria from './gematria.js';
import './App.css';

export default function KeyGeneration() {
  const [gematriaOutput, setGematriaOutput] = React.useState([]);

  function onChange(e) {
    const text = e.target.value.toLowerCase().replace(/\s+\.,'"\|!/, '');
    const gematria = new Gematria();
    
    if (gematria.isInputValid(text)) {
      setGematriaOutput(gematria.valuesFrom(text));
    }
  }

  function gematriaValues(offset = 0) {
    let currentXY = [50, 50];

    return gematriaOutput.map((value, index) => {
      if (index === 0) {
        return [...currentXY];
      }

      switch(index % 4 + offset) {
        case 0:
          currentXY[1] += value;
          break;
        case 1:
          currentXY[0] += value;
          break;
        case 2:
          currentXY[1] -= value;
          break;
        case 3:
          currentXY[0] -= value;
          break;
        default:
          break;
      }

      return [...currentXY];
    });
  }

  function gematriaLine(offset) {
    const output = gematriaValues(offset)
      .map(v => v.join(','))
      .join(' ');

    return (
      <polyline key={offset} points={output} stroke="black" fill="red" />
    )
  }

  function gematriaDrawing(initialViewbox, offsets) {
    let minMax = [[0, 0], [0, 0]];

    offsets.map(o => gematriaValues(o))
      .flat()
      .forEach((currentXY) => {
      if (currentXY[0] < minMax[0][0]) {
        minMax[0][0] = currentXY[0];
      }

      if (currentXY[1] < minMax[0][1]) {
        minMax[0][1] = currentXY[1];
      }

      if (currentXY[0] > minMax[1][0]) {
        minMax[1][0] = currentXY[0];
      }

      if (currentXY[1] > minMax[1][1]) {
        minMax[1][1] = currentXY[1];
      }
    });

    return (
      <svg
        id="gematria-key-generation-palette"
        viewBox={minMax.join(' ')}>
        {[0, 1, 2, 3].map(i => gematriaLine(i))}
      </svg>
    );
  }

  return (
    <div className="key-generation">
      <input
        id="gematria-key"
        name="gematria-key"
        onChange={onChange}
      />
      {gematriaDrawing('1 -1 120 120', [0, 1, 2, 3])}
    </div>
  );
}