import React from 'react';
import KeyGeneration from './KeyGeneration';
import './App.css';

export default function IndexPage() {
  function footerText(pageName) {
    const times = Math.random() * 20 | 0;
    let text = '';

    for (let i = 0; i < times; i++) {
      if (Math.random() > 0.9) {
        break;
      }

      text += 'the page is ever '
    }

    return (
      <div className="footer">
        <p>{text} <span className="footer-page-name">the {pageName}</span></p>
      </div>
    );
  }

  return (
    <div className="content">
      <h1 className="index-header">RALPH LENDREAU</h1>
      <KeyGeneration />
      {footerText('index')}
    </div>
  );
}