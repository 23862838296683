/**
 * Gematria class. Converts Latin characters to numbers.
 */
export default class Gematria {
  valuesFrom(string) {
    if (!this.isInputValid(string)) {
      return [];
    }

    return string.split('')
      .map((char) => Gematria.INTERNAL_MAPPING[char]);
  }

  isInputValid(string) {
    return !!string.match(Gematria.VALIDITY_REGEX);
  }
}

Gematria.VALIDITY_REGEX = /^[a-zA-Z]+$/;
Gematria.INTERNAL_MAPPING = {}
'abcdefghijklmnopqrstuvwxyz'
  .split('')
  .forEach((letter, index, __) => {
    Gematria.INTERNAL_MAPPING[letter] = index + 1;
  });
